
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "node_modules/font-awesome/scss/font-awesome.scss";

.body-background {
  background-image:url('http://www.connectionshousingapps.com/ExceptionForm/images/cheerBackground.png');
  background-position:center center;
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-size:cover;
  background-color:#111112;
}

.container-main {
  background: rgba(76, 76, 78, .9);
  min-height:100%;
  min-height:100vh;
  -moz-box-shadow: 0 0 4px 3px #2e2e2e;
  -webkit-box-shadow:  0 0 4px 3px #2e2e2e;
  box-shadow: 0 0 4px 3px #2e2e2e;
  padding-right:0;
  padding-left:0;
}

.img-logo {
  margin-top:20px;
  height:210px;
  max-height:210px;
  min-height:210px;
  width:210px;
  max-width:210px;
  min-width:210px;
}

.policy-section {
  color:#BCBCBE;
  padding-top:25px;
}

.section-headings {
  color:#f5f5f5;
}

.form-section-p1 {
  color:#BCBCBE;
  padding-bottom:15px;
  padding-top:15px;
}

.form-input-style {
  background-color:#fff;
  border-color:#303030;
  color:#000;
}

.form-input-margin-bot {
  margin-bottom:2px;
}

.form-button-style {
  background-color:#77278B;
  color:#BCBCBE;
  border-color:#303030;
  margin:10px;
}

.form-control:focus{
  border-color: #77278B;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #77278B;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #77278B;
}
.btn{
  margin: 0px;
  padding: 2px;
}
.btn:hover {
  background:#5f1f6f;
  border-color:#77278B;
  color:#BCBCBE;
}

.btn:focus {
  background:#5f1f6f;
  border-color:#77278B;
  color:#BCBCBE;
}

.btn:active {
  background:#1c1c1c;
  border-color:#77278B;
  color:#BCBCBE;
}

.btn:disabled {
  background:#BCBCBE;
  border-color:#77278B;
  color: #000000;
}

.glyphicon-inactive {
  font-size:2em;
  color:#BCBCBE;
}

.indicator-bar {
  background-color:#1c1c1c
}

.glyphicon-active {
  font-size:2em;
  color:#00A4A1;
}

.check-green {
  color:#00A4A1;
}

.x-yellow {
  color:#FFCF01;
}

.input-margin {
  margin-bottom:2px;
}

.grid-bottom-margin {
  margin-bottom:15px;
}

input:read-only {
  background-color: #f5f5f5;
  color: black
}

input:-moz-read-only {
  background-color: #f5f5f5;
  color: black
}